import React, { useEffect, useState } from "react";

import Error from "../common/ServerError";
import OverFlowToolTip from "../common/OverFlowToolTip";
import Spinner from "../common/Spinner";
import facetApi from "../../api/facetApi";
import { formatNumber } from "../common/formatHelper";
import { tokenRequest } from "../../authConfig";
import { useMsal } from "@azure/msal-react";

const ProjectTable = ({
  forecastRequest,
  config,
  selectedProjectRow,
  attributeConfiguration,
}) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [data, setData] = useState({ results: [] });
  const [request, setRequest] = useState({
    request: {
      ...forecastRequest.request,
      path: "projects",
      field: selectedProjectRow.attributeName.toLowerCase(),
      pageSize: 5,
      pageNumber: 1,
      refresh: true,
    },
    forecastDates: forecastRequest.forecastDates,
  });
  const { instance, accounts } = useMsal();

  useEffect(() => {
    if (!instance || !accounts || accounts.length === 0) {
      return;
    }

    if (!request) {
      return;
    }

    const accessTokenRequest = {
      ...tokenRequest,
      account: accounts[0],
    };

    instance
      .acquireTokenSilent(accessTokenRequest)
      .then((accessTokenResponse) => {
        const accessToken = accessTokenResponse.accessToken;
        (async () => {
          const data = await facetApi
            .list(accessToken, request.request)
            .then((data) => {
              setError(false);
              return data;
            })
            .catch((e) => {
              setError(true);
              console.log(e);
            })
            .finally(() => setLoading(false));
          setData(data);
        })();
      })
      .catch((error) => {
        setError(true);
      });
  }, [request, instance, accounts]);

  const handleNext = () => {
    setLoading(true);
    setRequest((prevRequest) => {
      return {
        request: {
          ...prevRequest.request,
          pageSize: prevRequest.request.pageSize,
          pageNumber: prevRequest.request.pageNumber + 1,
        },
        forecastDates: prevRequest.forecastDates,
      };
    });
  };

  if (loading) {
    return <Spinner />;
  }

  if (error) {
    return <Error />;
  }

  const getProjectsTemplate = () => {
    if (data == null || !data.results) {
      return;
    }

    const body = data.results.map((project) => {
      return (
        <tr key={project.name}>
          <th
            scope="col"
            className="name"
            style={{
              backgroundColor: attributeConfiguration.tableRowBackgroundColor,
              color: attributeConfiguration.tableRowTextColor,
            }}
          >
            <div className="flex">
              <div className="actions small">
                <span></span>
                <span></span>
              </div>
              <div style={{ width: "11rem" }}>
                <OverFlowToolTip>{project.name}</OverFlowToolTip>
              </div>
            </div>
          </th>
          {request.forecastDates.map((forecastdate) => {
            const horizon = project.timeHorizon.find(
              (r) => r.label === forecastdate
            );
            const value = horizon != null ? horizon.value : "";
            return (
              <td
                className="text-end"
                key={project.name + forecastdate + value}
                style={{
                  backgroundColor:
                    attributeConfiguration.tableRowBackgroundColor,
                  color: attributeConfiguration.tableRowTextColor,
                }}
              >
                <div style={{ width: "6rem" }}>
                  <OverFlowToolTip>
                    {formatNumber(value, config.decimalPlaces)}
                  </OverFlowToolTip>
                </div>
              </td>
            );
          })}
        </tr>
      );
    });

    return (
      <React.Fragment>
        <table className="animate fadeIn slow forecast table table-lg table-striped-custom mb-0">
          <tbody>{body}</tbody>
        </table>
        <div className="row py-3">
          <div className="flex middle">
            <button
              className="button"
              onClick={() => handleNext()}
              disabled={!data.hasNext}
            >
              Load More
            </button>
          </div>
        </div>
      </React.Fragment>
    );
  };

  return <React.Fragment>{getProjectsTemplate()}</React.Fragment>;
};

export default ProjectTable;
