import * as React from "react";

import {
  Category,
  ChartComponent,
  ColumnSeries,
  DataLabel,
  Inject,
  Legend,
  LineSeries,
  SeriesCollectionDirective,
  SeriesDirective,
  Tooltip,
} from "@syncfusion/ej2-react-charts";

import { formatNumber } from "../common/formatHelper";

class ForecastChart extends React.Component {
  constructor() {
    super(...arguments);
    this.fontFamily = "DM Sans";
    this.color = "#081644";
    this.fontWeight = "bold";
    this.size = "13px";
    this.palette = [
      ...this.props.config.attributeConfigurations
        .filter((c) => c.isVisible && c.chartType.toLowerCase() !== "none")
        .map((c) => c.chartColor),
    ];
    this.primaryxAxis = {
      interval: 1,
      valueType: "Category",
      labelStyle: {
        fontFamily: this.fontFamily,
        color: this.color,
        fontWeight: this.fontWeight,
        size: this.size,
      },
    };
    this.primaryyAxis = {
      minimum: 0,
      maximum: this.props.data.maxValue,
      labelStyle: {
        fontFamily: this.fontFamily,
        color: this.color,
        fontWeight: this.fontWeight,
        size: this.size,
      },
    };
    this.marker = {
      visible: true,
      width: 5,
      height: 5,
      opacity: 0.9,
    };
    this.template = this.chartTemplate;
    this.tooltip = {
      enable: true,
      shared: true,
      textStyle: { fontFamily: this.fontFamily },
      fill: "#1e2d3f",
    };
  }

  labelRender(args) {
    if (args.axis.name === "primaryYAxis") {
      var value = parseInt(args.value);
      if (value >= 1000 && value < 99999) {
        var kText = (value / 1000).toFixed(2);
        args.text = kText + "K";
      } else if (value > 99999) {
        var text = (value / 1000000).toFixed(2);
        args.text = text + "M";
      }
    }
  }

  sharedTooltipRender(args) {
    let seriesArray = args.text;
    for (let i = 0; i < seriesArray.length; i++) {
      const formattedValue = formatNumber(
        args.data[i].pointY,
        this.props.config.decimalPlaces
      );
      args.text[i] = `${args.data[i].seriesName} : <b>${formattedValue}</b>`;
    }
  }

  render() {
    const series = this.props.config.attributeConfigurations
      .filter((c) => c.isVisible && c.chartType.toLowerCase() !== "none")
      .map((c) => (
        <SeriesDirective
          key={c.attributeName}
          dataSource={this.props.data}
          xName="label"
          yName={c.attributeName.toLowerCase()}
          name={c.displayName}
          type={c.chartType}
          marker={c.chartType === "Line" ? this.marker : null}
        ></SeriesDirective>
      ));

    return (
      <div className="col-12">
        <div className="card shadow-sm rounded-extra-lg pt-3">
          <div className="card-body" key="forecastchart">
            <ChartComponent
              useGroupingSeparator={true}
              className="charts"
              primaryXAxis={this.primaryxAxis}
              primaryYAxis={this.primaryyAxis}
              palettes={this.palette}
              tooltip={this.tooltip}
              axisLabelRender={this.labelRender.bind(this)}
              sharedTooltipRender={this.sharedTooltipRender.bind(this)}
              legendSettings={{
                textStyle: {
                  fontFamily: this.fontFamily,
                  fontWeight: "500",
                  size: "15px",
                  color: "#283647",
                },
              }}
            >
              <Inject
                services={[
                  ColumnSeries,
                  LineSeries,
                  Legend,
                  Tooltip,
                  DataLabel,
                  Category,
                ]}
              />

              <SeriesCollectionDirective>{series}</SeriesCollectionDirective>
            </ChartComponent>
          </div>
        </div>
      </div>
    );
  }
}
export default ForecastChart;
