import React, { useEffect, useState } from "react";
import { addMonths, isTrue, subtractMonths } from "./../common/utilities";

import { ReactComponent as BackIcon } from "../../components/icons/arrow_icon.svg";
import Error from "../common/ServerError";
import { FileExport } from "../common/excel/FileExport";
import ForecastChart from "./DbForecastChart";
import ForecastTable from "./DbForecastTable";
import ItemAreaForecastTable from "./DbItemAreaForecastTable";
import { ReactComponent as OptionsIcon } from "../../components/icons/list_ul_options_icon.svg";
import OverrideOptions from "../override-options/OverrideOptions";
import Spinner from "../common/Spinner";
import actionType from "../../constants/actionType";
import applyToTimeBuckets from "../../constants/applyToTimeBuckets";
import forecastApi from "../../api/forecastApi";
import overrideOptions from "../../constants/overrideOptions";
import { tokenRequest } from "../../authConfig";
import { useLocation } from "react-router-dom";
import { useMsal } from "@azure/msal-react";

const DbForecast = ({
  config,
  selectedSearchData,
  showChart,
  currentAction,
  setCurrentAction,
  handleSearchCallback,
  checkDataEditLock,
  setAuthorizationError,
  handleBack,
  isUserAuthorized,
}) => {
  const location = useLocation();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [results, setResults] = useState({});
  const [request, setRequest] = useState(null);
  const { instance, accounts } = useMsal();
  const [errorMessages, setErrorMessages] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [saveOnlyChangedTimeBuckets, setSaveOnlyChangedTimeBuckets] = useState(
    config && config.defaultApplyToTimeBucketsValue === applyToTimeBuckets.All
      ? false
      : true
  );

  const [options, setOptions] = useState([
    {
      value: overrideOptions.RemoveOverrides,
      description: overrideOptions.RemoveOverrides,
      label:
        "Overrides will be replaced with the statistical forecast for the selection",
      isSelected: false,
    },
  ]);

  const [showOptionsModal, setShowOptionsModal] = useState(false);

  useEffect(() => {
    if (!config || !config?.fields?.length > 0) {
      return;
    }

    setRequest((req) => {
      if (req == null) {
        return {
          path: "forecast",
          field: config.fields,
          selectedFacetsFilterData: selectedSearchData?.selectedFilterData,
          afterKey: { value: "", description: "" },
          query: "",
          unitOfMeasurements: selectedSearchData?.unitOfMeasurements,
          currency: selectedSearchData?.currency,
          itemAreaLevel:
            config?.isItemAreaForecastEditable &&
            currentAction != null &&
            currentAction.areaCode != null &&
            currentAction.item != null,
          fromDate: subtractMonths(config.forecastFromMonths),
          toDate: addMonths(config.forecastToMonth),
        };
      } else if (
        JSON.stringify(req.selectedFacetsFilterData) !==
          JSON.stringify(selectedSearchData) ||
        JSON.stringify(req.unitOfMeasurements) !==
          JSON.stringify(selectedSearchData.unitOfMeasurements) ||
        JSON.stringify(req.currency) !==
          JSON.stringify(selectedSearchData.currency)
      ) {
        return {
          ...req,
          selectedFacetsFilterData: selectedSearchData.selectedFilterData,
          unitOfMeasurements: selectedSearchData.unitOfMeasurements,
          currency: selectedSearchData.currency,
        };
      } else {
        return {
          ...req,
        };
      }
    });
  }, [selectedSearchData, config, currentAction]);

  const setCalculatedFields = (data) => {
    config.attributeConfigurations.forEach(function (c) {
      data.forecast.forEach(function (item) {
        let total = 0;
        const calculatedFields = c.calculatedFields;

        // refactor to support other fields
        if (calculatedFields != null) {
          const calculatedFieldsList = calculatedFields.split(",");
          for (const field of calculatedFieldsList) {
            const value = item[field.toLowerCase()];
            if (value !== undefined && value !== null) {
              total += value;
            } else {
              console.log(field, value);
            }
          }

          item[c.attributeName.toLowerCase()] = total;
        }
      });
    });

    return data;
  };

  useEffect(() => {
    if (!instance || !accounts || accounts.length === 0) {
      return;
    }

    if (!request) {
      return;
    }

    setLoading(true);

    const accessTokenRequest = {
      ...tokenRequest,
      account: accounts[0],
    };

    instance
      .acquireTokenSilent(accessTokenRequest)
      .then((accessTokenResponse) => {
        const accessToken = accessTokenResponse.accessToken;
        (async () => {
          const data = await forecastApi
            .list(accessToken, request)
            .then((data) => {
              setError(false);
              setAuthorizationError(null);
              return data;
            })
            .catch((e) => {
              if (e && e?.response?.status === 403) {
                setAuthorizationError(e);
              } else {
                setError(e);
              }
              console.log(e);
            })
            .finally(() => setLoading(false));
          setCalculatedFields(data);
          setResults(data);
        })();
      })
      .catch((error) => {
        setError(true);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [request, instance, accounts, refresh]);

  const handleOptionsSelection = (updatedData) => {
    setOptions(updatedData);
  };

  const handleOptionsModel = () => {
    setShowOptionsModal((showOptionsModal) => {
      return !showOptionsModal;
    });
  };

  const anyOptionsSelected = () => {
    return options.find((o) => o.isSelected);
  };

  const handleSave = (
    timeHorizonValuesToBeCreated,
    attributeName,
    areaCode,
    item
  ) => {
    const lockEdit = checkDataEditLock(config.enableEditLock, setErrorMessages);

    if (lockEdit) {
      return;
    }

    if (timeHorizonValuesToBeCreated.length <= 0) {
      let errors = [];
      errors.push({
        name: attributeName,
        value:
          "Values are not changed, please update atleast one month to save Or Select Apply to time buckets as All to save all time buckets",
      });
      setErrorMessages(errors);
      return;
    }
    setErrorMessages([]);
    const filters =
      selectedSearchData != null &&
      selectedSearchData.selectedFilterData != null
        ? selectedSearchData.selectedFilterData.map((filterData) => {
            return {
              parent: filterData.path,
              field: filterData.field,
              items: filterData.items,
            };
          })
        : null;

    let itemAreaSelections = [];

    if (item != null && item.itemCode != null && areaCode != null) {
      itemAreaSelections.push({
        itemCode: item.itemCode,
        areaCode: areaCode,
        isSelected: true,
      });
    }

    const overrideItemAreaSelection = {
      overrideId: 0,
      filters: filters,
      itemAreaSelectionContainer: {
        selectAll: false,
        itemAreaSelections: itemAreaSelections,
      },
      additionalInfo: location.search,
    };

    let saveRequest = {
      attributeName: attributeName,
      uoM: selectedSearchData.unitOfMeasurements,
      overrideTimeHorizon: timeHorizonValuesToBeCreated,
      overrideItemAreaSelection: overrideItemAreaSelection,
      itemAreaLevel: request.itemAreaLevel,
      currency: request.currency,
    };

    if (anyOptionsSelected()) {
      const selectedOption = options.find((option) => option.isSelected);

      switch (selectedOption.value) {
        case overrideOptions.RemoveOverrides:
          saveRequest = {
            ...saveRequest,
            removeOverrides: selectedOption.isSelected,
          };
          break;

        default:
          break;
      }
    }

    if (!instance || !accounts || accounts.length === 0) {
      return;
    }

    setLoading(true);

    const accessTokenRequest = {
      ...tokenRequest,
      account: accounts[0],
    };

    instance
      .acquireTokenSilent(accessTokenRequest)
      .then((accessTokenResponse) => {
        const accessToken = accessTokenResponse.accessToken;
        (async () => {
          await forecastApi
            .override(accessToken, saveRequest)
            .then((data) => {
              setError(null);
              setErrorMessages([]);
              setRefresh(true);
              handleSearchCallback();
              setCurrentAction({
                attributeConfiguration: null,
                action: actionType.Read,
              });
              return data;
            })
            .catch((error) => {
              console.log(error);
              if (
                error.response?.status === 400 &&
                error.response?.data?.errors
              ) {
                let errors = [];
                for (const [key, value] of Object.entries(
                  error.response.data.errors
                )) {
                  errors.push({ name: key, value: value });
                }
                setErrorMessages(errors);
              } else {
                setError(error);
              }
            })
            .finally(() => setLoading(false));
        })();
      })
      .catch((error) => {
        setLoading(false);
        setError(error);
      });
  };

  const hasItemAreaForecast = () => {
    return (
      results != null &&
      results.itemAreaForecast != null &&
      results.itemAreaForecast.forecast != null
    );
  };

  const hasTotalForecast = () => {
    return (
      results != null && results.forecast != null && results.forecast.length > 0
    );
  };

  const hasForecast = () => {
    return hasTotalForecast() || hasItemAreaForecast();
  };

  const handleSavingOfTimeBuckets = (value) => {
    setSaveOnlyChangedTimeBuckets(value);
  };

  const getExcelData = (forecastData) => {
    const excelData = [];

    const attributeNameHeader =
      config.attributeDisplayNameHeader != null
        ? config.attributeDisplayNameHeader
        : "Name";

    config.attributeConfigurations.forEach((c) => {
      let dataByHeader = {
        [attributeNameHeader]: c.displayName,
      };

      forecastData.forEach((item) => {
        dataByHeader[item.label] = item[c.attributeName.toLowerCase()];
      });

      excelData.push(dataByHeader);
    });

    return excelData;
  };

  if (error) {
    return <Error error={error} />;
  }

  return (
    <React.Fragment>
      <div className="row">
        {errorMessages && errorMessages.length > 0 ? (
          <div className="col-12 ms-3">
            {errorMessages.map((e, i) => {
              return (
                <div key={i} className="mb-2 text-danger">
                  {e.value}
                </div>
              );
            })}
          </div>
        ) : (
          ""
        )}
      </div>

      <div className="col-12">
        <div className="flex py-2">
          <div className="flex">
            <button type="button" className="link" onClick={() => handleBack()}>
              <span className="arrow-icon back">
                <BackIcon />
              </span>
              <span className="font-weight-medium">Back</span>
            </button>
          </div>
          <div className="flex flex-end pe-2">
            <button type="button" className="link" onClick={handleOptionsModel}>
              <span className="pe-1">
                <OptionsIcon />
              </span>
              <span className="font-weight-medium">Options</span>
            </button>
          </div>
        </div>
        <div className="ps-2">
          <OverrideOptions
            showOptionsModal={showOptionsModal}
            handleOptionsModel={handleOptionsModel}
            handleOptionsSelection={handleOptionsSelection}
            handleOptionsCancel={handleOptionsSelection}
            options={options}
          />
        </div>

        {loading ? (
          <div className="col-12">
            <Spinner></Spinner>
          </div>
        ) : (
          <React.Fragment>
            {hasForecast() ? (
              <React.Fragment>
                {showChart ? (
                  <div className="row">
                    <ForecastChart data={results.forecast} config={config} />
                  </div>
                ) : null}
                {isTrue(config.showForecastTotal) &&
                isTrue(config.isForecastTotalEditable) &&
                hasTotalForecast() ? (
                  <div className="row mt-2">
                    <div className="col-12" key="forecasttable">
                      <div className="card shadow-sm rounded-extra-lg">
                        {config.totalTableHeader != null ? (
                          <h4 className="pt-3 ps-4">
                            {config.totalTableHeader}
                          </h4>
                        ) : (
                          ""
                        )}
                        <div className="card-body">
                          <div className="flex">
                            <div className="flex switch-bar middle mb-3">
                              <div className="switch-container">
                                <fieldset className="switch-fieldset">
                                  <legend className="switch-legend">
                                    Apply to time buckets
                                  </legend>
                                  <div className="switch-group">
                                    <label className="switch-wrap">
                                      <input
                                        type="radio"
                                        name="timeBucketSelection"
                                        key="allTimeBucketSelection"
                                        checked={!saveOnlyChangedTimeBuckets}
                                        onChange={(e) =>
                                          handleSavingOfTimeBuckets(false)
                                        }
                                      />
                                      <span className="switch">All</span>
                                    </label>
                                    <label className="switch-wrap">
                                      <input
                                        type="radio"
                                        name="timeBucketSelection"
                                        key="changedTimeBucketSelection"
                                        checked={saveOnlyChangedTimeBuckets}
                                        onChange={(e) =>
                                          handleSavingOfTimeBuckets(true)
                                        }
                                      />
                                      <span className="switch">Changed</span>
                                    </label>
                                  </div>
                                </fieldset>
                              </div>
                            </div>
                            {isTrue(config.enableForecastTotalExport) ? (
                              <div className="flex-end">
                                <FileExport
                                  apiData={getExcelData(results?.forecast)}
                                  fileName={"Forecast"}
                                />
                              </div>
                            ) : null}
                          </div>
                          <div className="mb-2 table-responsive scrollbar horizontal">
                            <div className="scrollbar-content">
                              <ForecastTable
                                data={results.forecast}
                                config={config}
                                handleSave={handleSave}
                                currentAction={currentAction}
                                saveOnlyChangedTimeBuckets={
                                  saveOnlyChangedTimeBuckets
                                }
                                forecastRequest={{
                                  request: request,
                                  forecastDates: results.forecast.map(
                                    (i) => i.label
                                  ),
                                }}
                                anyOptionsSelected={anyOptionsSelected}
                                isUserAuthorized={isUserAuthorized}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
                {isTrue(config.showItemAreaForecast) &&
                isTrue(config.isItemAreaForecastEditable) &&
                hasItemAreaForecast() ? (
                  <div className="row mt-4">
                    <div className="col-12" key="forecasttable">
                      <div className="card shadow-sm rounded-extra-lg">
                        <h4 className="pt-3 ps-4">
                          {config.itemAreaTableHeader != null
                            ? config.itemAreaTableHeader
                            : "Forecast"}
                        </h4>
                        <div className="card-body">
                          <div className="mb-2 table-responsive scrollbar horizontal">
                            <div className="scrollbar-content">
                              <ItemAreaForecastTable
                                data={results.itemAreaForecast}
                                config={config}
                                handleSave={handleSave}
                                currentAction={currentAction}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
              </React.Fragment>
            ) : (
              <div className="col-12 mt-3">
                <div className="text-center text-danger">
                  No forecast data available for the selected filters
                </div>
              </div>
            )}
            <div></div>
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  );
};

export default DbForecast;
