import Modal from "../common/modal/modal";
import React from "react";
import actionType from "../../constants/actionType";

const OverrideOptions = (props) => {
  const handleOptionsSelection = (updatedData) => {
    props.handleOptionsSelection(updatedData);
  };

  const handleOptionsOk = () => {
    console.log();
  };

  const handleOptionsCancel = () => {
    const updatedOptions = props.options.map((item) => {
      return { ...item, isSelected: false };
    });

    props.handleOptionsSelection(updatedOptions);
  };

  const anyOptionsSelected = () => {
    return props.options?.find((o) => o.isSelected);
  };

  return (
    <React.Fragment>
      <div className="col-12">
        {props.showOptionsModal ? (
          <Modal
            actionType={actionType.Options}
            header={"Options"}
            options={props.options}
            setModalOpen={props.handleOptionsModel}
            handleCancel={handleOptionsCancel}
            handleAction={handleOptionsSelection}
            handleOk={handleOptionsOk}
          />
        ) : (
          ""
        )}
        <div className="options">
          {anyOptionsSelected() ? (
            <React.Fragment>
              <div className="small-heading">Selected Option</div>
              <ul>
                {props.options
                  .filter((option) => option.isSelected)
                  .map((option) => {
                    return (
                      <li key={option.value}>
                        <div className="mb-2">
                          <span className="value text-danger">
                            {option.value}
                          </span>
                          {option.label ? (
                            <span className="label"> - {option.label}</span>
                          ) : null}
                        </div>
                      </li>
                    );
                  })}
              </ul>
            </React.Fragment>
          ) : null}
        </div>
      </div>
    </React.Fragment>
  );
};

export default OverrideOptions;
