import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { ReactComponent as ArrowIcon } from "../../components/icons/arrow_icon.svg";
import Error from "../common/ServerError";
import OverFlowToolTip from "../common/OverFlowToolTip";
import { ReactComponent as SearchIcon } from "../../components/icons/search_icon.svg";
import Spinner from "../common/Spinner";
import facetApi from "../../api/facetApi";
import { tokenRequest } from "../../authConfig";
import unitOfMeasurements from "../../constants/unitOfMeasurements";
import urlHelper from "../common/urlHelper";
import { useMsal } from "@azure/msal-react";

function Filter({
  updateSearch,
  getSelectedSearchData,
  path,
  attributeName,
  displayName,
  onRef,
  isWriteOperation,
  isSideBar,
  setShowFilterBackdrop,
}) {
  const location = useLocation();
  const navigate = useNavigate();
  const filterDialog = useRef(null);
  const [showModal, setShowModal] = useState(false);
  const [afterKey, setAfterKey] = useState(null);
  const [facetItems, setFacetItems] = useState([]);
  const [moreDataAvailable, setMoreDataAvailable] = useState(false);
  const { instance, accounts } = useMsal();

  const pageSize = isSideBar ? 8 : 48;
  const initialRequest = {
    showModal: false,
    path: path,
    field: attributeName,
    selectedFacetsFilterData: [],
    afterKey: { value: "", description: "" },
    pageNumber: 1,
    pageSize: pageSize,
    query: "",
    unitOfMeasurements: unitOfMeasurements.UNITS,
    isWriteOperation: isWriteOperation,
  };

  const [loading, setLoading] = useState(true);
  const [request, setRequest] = useState(initialRequest);

  const [searchQuery, setSearchQuery] = useState("");
  const [timoutId, setTimoutId] = useState("");
  const [error, setError] = useState(false);
  const [showSideBarFilter, setShowSideBarFilter] = useState(false);

  const [, getSearchParams] = urlHelper();

  const handleRemoveFilter = (value) => {
    const updatedFilterItem = facetItems.find((f) => f.value === value);
    updateStatus(facetItems, updatedFilterItem, false, null, value);
  };

  useEffect(() => {
    onRef((value) => {
      handleRemoveFilter(value);
    });
  });

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (
        filterDialog &&
        filterDialog.current &&
        filterDialog.current.parentNode &&
        !filterDialog.current.parentNode.contains(e.target)
      ) {
        setShowModal(false);
        setSearchQuery("");
        if (isSideBar) {
          setShowSideBarFilter(!showSideBarFilter);
        }

        if (
          !e.target.hasAttribute("data-bs-filter") &&
          !e.target.hasAttribute("data-bs-sidebar-filter")
        ) {
          setShowFilterBackdrop(false);
        }
      }
    };

    if (showModal) {
      document.addEventListener("click", handleOutsideClick, false);
    }
    return () => {
      document.removeEventListener("click", handleOutsideClick, false);
    };
  }, [showModal, isSideBar, showSideBarFilter, setShowFilterBackdrop]);

  const updateStatus = (
    data,
    selectedFilter,
    isSelected,
    clear,
    selectedFilterValue,
    searchTerm = false
  ) => {
    const searchParams = getSearchParams();

    let paramValues = [...searchParams.getAll(displayName)];
    let dataWithSelectedFlag = [];

    if (clear) {
      dataWithSelectedFlag = data.map((item) => {
        return { ...item, isSelected: false };
      });

      searchParams.delete(displayName);
    } else if (selectedFilter) {
      dataWithSelectedFlag = data.map((item) => {
        return selectedFilter === item
          ? { ...item, isSelected: isSelected }
          : item;
      });
    } else {
      dataWithSelectedFlag = data.map((item) => {
        return paramValues && paramValues.find((value) => value === item.value)
          ? { ...item, isSelected: true }
          : item;
      });
    }

    const selectedFilters = dataWithSelectedFlag.filter(
      (item) => item.isSelected
    );

    let searchParamKeys = [];
    for (let key of searchParams.keys()) {
      if (searchParamKeys.indexOf(key) === -1) {
        searchParamKeys.push(key);
      }
    }

    if (searchParamKeys.indexOf(displayName) !== -1) {
      for (let key of searchParamKeys) {
        if (key === displayName) {
          const values = searchParams.getAll(key);
          const allValues = [
            ...new Set([
              ...values,
              ...selectedFilters.map((item) => item.value),
            ]),
          ];
          searchParams.delete(key);
          if (isSelected) {
            allValues.map((value) => searchParams.append(displayName, value));
          } else {
            allValues
              .filter(
                (v) =>
                  v !==
                  (selectedFilter
                    ? selectedFilter.value
                    : selectedFilterValue
                    ? selectedFilterValue
                    : "")
              )
              .map((value) => searchParams.append(displayName, value));
          }
        } else {
          const values = searchParams.getAll(key);
          searchParams.delete(key);
          values.map((v) => searchParams.append(key, v));
        }
      }
    } else {
      selectedFilters.map((item) =>
        searchParams.append(displayName, item.value)
      );
    }

    navigate(`${location.pathname}?${searchParams}`, { replace: true });

    if (!searchTerm) {
      updateSearch(searchParams, attributeName);
    }

    setFacetItems(dataWithSelectedFlag);
  };

  const getData = async (handleMore = false, searchQuery) => {
    if (searchQuery) {
      searchQuery = searchQuery.replace(/\//g, "\\/");
    }

    if (!instance || !accounts || accounts.length === 0) {
      return;
    }

    var request = createRequest(handleMore, true, searchQuery);

    const accessTokenRequest = {
      ...tokenRequest,
      account: accounts[0],
    };

    instance
      .acquireTokenSilent(accessTokenRequest)
      .then((accessTokenResponse) => {
        const accessToken = accessTokenResponse.accessToken;
        (async () => {
          const data = await facetApi
            .list(accessToken, request)
            .then((data) => {
              setError(null);
              return data;
            })
            .catch((e) => {
              console.log(e);
              setError(e);
            })
            .finally(() => setLoading(false));

          const mappedData = mapFilterData(data);

          if (
            mappedData.facetItems.length > 0 &&
            mappedData.facetItems.length >= request.pageSize
          ) {
            setMoreDataAvailable(true);
          } else {
            setMoreDataAvailable(false);
          }

          if (mappedData.afterKey) {
            setAfterKey(mappedData.afterKey);
          }

          if (handleMore) {
            mappedData.facetItems = [...facetItems, ...mappedData.facetItems];
          }

          updateStatus(
            mappedData.facetItems,
            null,
            null,
            null,
            searchQuery,
            searchQuery ? true : false
          );
        })();
      })
      .catch((error) => {
        setError(error);
      });
  };

  const handleFilterButtonClick = (event) => {
    const show = showModal ? false : true;
    setShowModal(show);
    setFacetItems([]);
    setLoading(true);
    getData();
    if (isSideBar) {
      setShowSideBarFilter(!showSideBarFilter);
    } else {
      setShowFilterBackdrop(show);
    }
  };

  const handleFilterSearch = (value) => {
    clearTimeout(timoutId);
    setSearchQuery(value);
    const id = setTimeout(() => {
      getData(false, value);
    }, 500);
    setTimoutId(id);
  };

  const handleFilterCheckboxSelection = (updatedFilterItem, isSelected) => {
    updateStatus(facetItems, updatedFilterItem, isSelected);
  };

  const handleClear = (event) => {
    event.stopPropagation();
    setRequest(initialRequest);
    setSearchQuery("");
    updateStatus(facetItems, null, null, true);
  };

  const handleMore = (e) => {
    getData(true);
  };

  const handleApply = () => {
    setShowModal(false);

    if (isSideBar) {
      setShowSideBarFilter(!showSideBarFilter);
    }

    setShowFilterBackdrop(false);
  };

  const createRequest = (
    handleMore = false,
    showModal = true,
    searchQuery = ""
  ) => {
    const selectedData = getSelectedSearchData(attributeName);
    const updatedRequest = {
      ...request,
      showModal: showModal,
      selectedFacetsFilterData: [...selectedData.selectedFilterData],
      afterKey: handleMore
        ? afterKey
          ? afterKey
          : request.afterKey
        : showModal
        ? initialRequest.afterKey
        : request.afterKey,
      pageNumber: handleMore
        ? request.pageNumber + 1
        : initialRequest.pageNumber,
      pageSize: request.pageSize,
      query: searchQuery,
      unitOfMeasurements: selectedData.unitOfMeasurements,
    };

    setRequest(updatedRequest);

    return updatedRequest;
  };

  const dataAvailable = () => {
    return facetItems != null && facetItems.length > 0;
  };

  const filterTemplate = () => {
    const searchParams = getSearchParams();
    const values = searchParams.getAll(displayName);
    return (
      <React.Fragment>
        <button
          type="button"
          className={`${isSideBar ? "tab" : "button small"}`}
          data-bs-toggle="collapse"
          data-bs-target={`#${displayName}`}
          aria-expanded="false"
          data-bs-filter="true"
          onClick={handleFilterButtonClick}
        >
          {isSideBar ? (
            <span
              className={`svg medium me-2 arrow-icon ${
                showSideBarFilter ? "expanded" : "collapsed"
              }`}
            >
              <ArrowIcon />
            </span>
          ) : null}
          {displayName}
        </button>
        <div ref={filterDialog}>
          {showModal && (
            <div
              id={`#${displayName}`}
              className={`${
                isSideBar
                  ? "filter-dialog sidebar animate fadeIn fast"
                  : "filter-dialog animate fadeIn fast"
              }`}
            >
              {loading ? (
                <Spinner />
              ) : error ? (
                <div className="col-12">
                  <Error error={error} showHomeButton={false} />
                </div>
              ) : (
                <form>
                  <React.Fragment>
                    <div className="scrollbar">
                      <div className="px-2 col-12 search-bar">
                        <span className="search-icon">
                          <SearchIcon />
                        </span>
                        <input
                          className="search-input border-radius form-control"
                          type="text"
                          placeholder="Search"
                          value={searchQuery}
                          onChange={(e) => handleFilterSearch(e.target.value)}
                        />
                      </div>
                      {!dataAvailable() ? (
                        <div className="col-12">
                          <div className="text-center text-danger m-5">
                            No results found
                          </div>
                        </div>
                      ) : (
                        <div className="filter-fields">
                          <div className="checkbox-fields">
                            {facetItems.map((item) => {
                              const isItemInParam = values.find(
                                (param) => param === item.value
                              );
                              const noCount = item.count === 0;
                              return (
                                <div
                                  className="filter-value-container"
                                  key={item.value}
                                >
                                  <label className="checkbox-field">
                                    <div className="checkbox-container">
                                      <input
                                        className="input"
                                        type="checkbox"
                                        name={item.value}
                                        checked={item.isSelected ? true : false}
                                        disabled={noCount && isItemInParam}
                                        onChange={(e) =>
                                          handleFilterCheckboxSelection(
                                            item,
                                            e.target.checked
                                          )
                                        }
                                      />
                                      <span></span>
                                    </div>
                                    <div className="filter-value">
                                      {item.value}
                                    </div>
                                    <div className="filter-description">
                                      <OverFlowToolTip>
                                        {item.description}
                                      </OverFlowToolTip>
                                    </div>
                                  </label>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </div>
                    {dataAvailable() ? (
                      <div className="filter-actions">
                        <button
                          type="button"
                          className="link clear"
                          onClick={handleClear}
                        >
                          Clear
                        </button>

                        {moreDataAvailable ? (
                          <button
                            type="button"
                            className="link light-bold"
                            onClick={handleMore}
                          >
                            More...
                          </button>
                        ) : null}
                        <button
                          type="button"
                          className="button small"
                          data-bs-dismiss="offcanvas"
                          aria-label="Close"
                          onClick={handleApply}
                        >
                          Apply
                        </button>
                      </div>
                    ) : null}
                  </React.Fragment>
                </form>
              )}
            </div>
          )}
        </div>
      </React.Fragment>
    );
  };

  function mapFilterData(json) {
    const mappedData = JSON.parse(JSON.stringify(json), function (prop, value) {
      switch (prop) {
        case "ak":
          this.afterKey = value;
          return;
        case "fi":
          this.facetItems = value;
          return;
        case "fd":
          this.field = value;
          return;
        case "ct":
          this.count = value;
          return;
        case "de":
          this.description = value;
          return;
        case "vl":
          this.value = value;
          return;
        default:
          return value;
      }
    });

    return mappedData;
  }

  return (
    <div className={`${isSideBar ? "tab-seperator" : "me-2"}`}>
      {filterTemplate()}
    </div>
  );
}

export default Filter;
