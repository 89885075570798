import React, { useState } from "react";

import { ReactComponent as ArrowIcon } from "../../components/icons/arrow_icon.svg";
import OverFlowToolTip from "../common/OverFlowToolTip";
import ProjectTable from "./ProjectTable";
import actionType from "../../constants/actionType";
import claims from "../../constants/claims";
import forecastViewType from "../../constants/forecastViewType";
import { formatNumber } from "../common/formatHelper";
import { isTrue } from "../common/utilities";

function ForecastTable({
  data,
  config,
  handleEdit,
  currentView,
  forecastRequest,
  isUserAuthorized,
}) {
  const [showProjectTable, setShowProjectTable] = useState([]);
  const [selectedProjectRow, setSelectedProjectRow] = useState();

  const handleShowProjects = (c) => {
    if (!c) {
      return;
    }

    setSelectedProjectRow(c);

    setShowProjectTable((previousSelections) => {
      const match = previousSelections.find(
        (p) => p.attributeName === c.attributeName
      )?.value;
      return [
        ...previousSelections.filter(
          (p) => p.attributeName !== c.attributeName
        ),
        {
          attributeName: c.attributeName,
          value: !match,
        },
      ];
    });
  };

  const isProjectRowExpanded = (c) => {
    return showProjectTable.find((p) => p.attributeName === c.attributeName)
      ?.value;
  };

  const getForecastTemplate = () => {
    const dateLables = () =>
      data.map((item) => {
        return (
          <th
            scope="col"
            style={{ width: "6rem" }}
            className="text-end"
            key={item.label}
          >
            {item.label}
          </th>
        );
      });

    const rows = () =>
      config.attributeConfigurations.map((c) => {
        return (
          <React.Fragment
            key={"forecast-rows" + c.parent + c.attributeName + c.displayName}
          >
            <tr key={c.attributeName}>
              <th
                scope="col"
                className="name"
                style={{
                  backgroundColor: c.tableRowBackgroundColor,
                  color: c.tableRowTextColor,
                }}
              >
                <div className="flex">
                  <div
                    className={
                      isTrue(config.isForecastTotalEditable)
                        ? "actions small"
                        : ""
                    }
                  >
                    {c.isEditable &&
                    isUserAuthorized(claims.ForecastOverrideWrite) &&
                    isTrue(config.isForecastTotalEditable) &&
                    currentView === forecastViewType.RollingView ? (
                      <button
                        className="link small bold underline"
                        onClick={() => handleEdit(c, actionType.Edit)}
                      >
                        <span>Edit</span>
                      </button>
                    ) : (
                      <span> </span>
                    )}
                    {c.parent === "Projects" ? (
                      <button
                        type="button"
                        className="link float-end"
                        onClick={() => handleShowProjects(c)}
                      >
                        <span
                          className={`arrow-icon ${
                            isProjectRowExpanded(c) === true
                              ? "expanded"
                              : "collapsed"
                          }`}
                          style={{
                            color: c.tableRowTextColor,
                          }}
                        >
                          <ArrowIcon />
                        </span>
                      </button>
                    ) : (
                      <span> </span>
                    )}
                  </div>
                  <div style={{ width: "11rem" }}>
                    <OverFlowToolTip>{c.displayName}</OverFlowToolTip>
                  </div>
                </div>
              </th>
              {data.map((item, index) => {
                return (
                  <td
                    className="text-end"
                    key={index + item.label + c.displayName + c.attributeName}
                    style={{
                      backgroundColor: c.tableRowBackgroundColor,
                      color: c.tableRowTextColor,
                    }}
                  >
                    <div
                      style={{ width: "6rem" }}
                      className={`${
                        c.attributeName.toLowerCase() ===
                          "commercialoverride" && item.isOverrideInProcess
                          ? "text-danger"
                          : ""
                      }`}
                    >
                      <OverFlowToolTip>
                        {formatNumber(
                          item[c.attributeName.toLowerCase()],
                          config.decimalPlaces
                        )}
                      </OverFlowToolTip>
                    </div>
                  </td>
                );
              })}
            </tr>
            {c.parent === "Projects" && isProjectRowExpanded(c) === true ? (
              <tr>
                <td colSpan="18" className="p-0">
                  <ProjectTable
                    forecastRequest={forecastRequest}
                    config={config}
                    selectedProjectRow={selectedProjectRow}
                    attributeConfiguration={c}
                  />
                </td>
              </tr>
            ) : null}
          </React.Fragment>
        );
      });

    return (
      <React.Fragment>
        <table
          className={`forecast table table-lg table-striped-custom ${
            config.totalTableHeader != null ? "table-header-border" : ""
          }`}
        >
          <thead>
            <tr>
              <th scope="col">
                <div className="flex">
                  <div
                    className={
                      isTrue(config.isForecastTotalEditable)
                        ? "actions small"
                        : ""
                    }
                  ></div>
                  <div className="text-start">
                    {config.attributeDisplayNameHeader != null
                      ? config.attributeDisplayNameHeader
                      : "Name"}
                  </div>
                </div>
              </th>
              {dateLables()}
            </tr>
          </thead>
          <tbody>{rows()}</tbody>
        </table>
      </React.Fragment>
    );
  };

  return <React.Fragment>{getForecastTemplate()}</React.Fragment>;
}

export default ForecastTable;
