import { ReactComponent as BackIcon } from "../../components/icons/arrow_icon.svg";
import ItemArea from "./ItemArea";
import ProjectCard from "./ProjectCard";
import React from "react";

const ProjectDetail = (props) => {
  const createProjectDetail = () => {
    return (
      <React.Fragment>
        <div className="flex pt-3">
          <div className="flex">
            <h4>Project Details</h4>
          </div>
        </div>
        <div className="flex pt-2 pb-3">
          <div className="flex">
            <button
              type="button"
              className="link ms-n2"
              onClick={() => props.handleCancel()}
            >
              <span className="arrow-icon back">
                <BackIcon />
              </span>
              <span className="font-weight-medium">Back</span>
            </button>
          </div>
        </div>
        <ProjectCard
          project={props.project}
          currentStep={props.currentStep}
          handleDetailProject={props.handleDetailProject}
          handleEditProject={props.handleEditProject}
          handleDelete={props.handleDelete}
          configuration={props.configuration}
          hasWritePermissions={props.hasWritePermissions}
        ></ProjectCard>
        <div className="row">
          <div className="col">
            <div className="card shadow-sm rounded-extra-lg mb-3">
              <div className="card-body project-card">
                <ItemArea
                  currentProject={props.project}
                  detailView={true}
                  configuration={props.configuration}
                />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  };

  return <React.Fragment>{createProjectDetail()}</React.Fragment>;
};

export default ProjectDetail;
