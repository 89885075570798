import { ReactComponent as ConfirmIcon } from "../../../components/icons/check_circle_icon.svg";
import { ReactComponent as DeleteIcon } from "../../../components/icons/trash_icon.svg";
import { ReactComponent as ErrorIcon } from "../../../components/icons/error_icon.svg";
import React from "react";
import { ReactComponent as WarningIcon } from "../../../components/icons/warning_icon.svg";
import actionType from "../../../constants/actionType";

const Modal = (props) => {
  const handleCancel = () => {
    if (props.handleCancel) {
      props.handleCancel();
    }

    props.setModalOpen(false);
  };

  const handleOk = () => {
    if (props.handleOk) {
      props.handleOk();
    }

    props.setModalOpen(false);
  };

  const handleOptionsSelection = (updatedItem) => {
    const updatedOptions = props.options.map((item) => {
      return updatedItem === item
        ? { ...item, isSelected: true }
        : { ...item, isSelected: false };
    });
    props.handleAction(updatedOptions);
  };

  function getTemplate(type) {
    switch (type) {
      case actionType.Confirm:
        return (
          <React.Fragment>
            <div className="icon">
              <ConfirmIcon />
            </div>
            <div className="header">
              <div>Confirm {props.name}</div>
            </div>
            <div className="sub-header">
              <p>This action can't be undone, please confirm.</p>
            </div>
            <div className="modal-dialog-footer">
              <button
                type="button"
                className="button me-2"
                onClick={() => props.handleAction(props.actionCallbackValue)}
                disabled={props.actionInProgress}
              >
                {props.actionType}
                {props.actionInProgress ? (
                  <span className="disabled ms-2">
                    <span
                      className="spinner-grow spinner-grow-1 me-1"
                      role="status"
                    ></span>
                    <span
                      className="spinner-grow spinner-grow-2 me-1"
                      role="status"
                    ></span>
                    <span className="spinner-grow me-1" role="status"></span>
                  </span>
                ) : null}
              </button>
              <button
                type="submit"
                className="button danger"
                onClick={() => handleCancel()}
              >
                Cancel
              </button>
            </div>
          </React.Fragment>
        );

      case actionType.Delete:
        return (
          <React.Fragment>
            <div className="icon">
              <DeleteIcon />
            </div>
            <div className="header">
              <div>Delete {props.name}</div>
            </div>
            <div className="sub-header">
              <p>This action can't be undone, please confirm.</p>
            </div>
            <div className="modal-dialog-footer">
              <button
                type="submit"
                className="button me-2"
                onClick={() => handleCancel()}
              >
                Cancel
              </button>
              <button
                type="button"
                className="button danger"
                onClick={() => props.handleAction(props.actionCallbackValue)}
                disabled={props.actionInProgress}
              >
                {props.actionType}
                {props.actionInProgress ? (
                  <span className="disabled ms-2">
                    <span
                      className="spinner-grow spinner-grow-1 me-1"
                      role="status"
                    ></span>
                    <span
                      className="spinner-grow spinner-grow-2 me-1"
                      role="status"
                    ></span>
                    <span className="spinner-grow me-1" role="status"></span>
                  </span>
                ) : null}
              </button>
            </div>
          </React.Fragment>
        );
      case actionType.Warning:
        return (
          <React.Fragment>
            <div className="icon text-warning">
              <WarningIcon />
            </div>
            <div className="header text-warning">
              <div>{props.header}</div>
            </div>
            <div className="sub-header">
              <p>{props.subHeader}</p>
            </div>
            <div className="modal-dialog-footer">
              {props.handleCancel ? (
                <button
                  type="submit"
                  className="button me-2"
                  onClick={() => handleCancel()}
                >
                  Cancel
                </button>
              ) : null}
              {props.handleAction ? (
                <button
                  type="button"
                  className="button warning"
                  onClick={() => props.handleAction(props.actionCallbackValue)}
                >
                  Ok
                </button>
              ) : null}
            </div>
          </React.Fragment>
        );
      case actionType.Error:
        return (
          <React.Fragment>
            <div className="icon text-danger">
              <ErrorIcon />
            </div>
            <div className="header text-danger">
              <div>{props.header}</div>
            </div>
            <div className="sub-header">
              <p>{props.subHeader}</p>
            </div>
          </React.Fragment>
        );
      case actionType.Help:
        return (
          <React.Fragment>
            <div className="icon help">
              <div>
                <h4>Help</h4>
              </div>
              {props.handleCancel ? (
                <button
                  type="submit"
                  className="button me-2"
                  onClick={() => handleCancel()}
                >
                  Close
                </button>
              ) : null}
            </div>
            <div className="header text-primary">
              <div className="help">{props.header}</div>
            </div>

            <div>{props.content}</div>
            {props.handleCancel ? (
              <div className="flex flex-end">
                <button
                  type="submit"
                  className="button me-2"
                  onClick={() => handleCancel()}
                >
                  Close
                </button>
              </div>
            ) : null}
          </React.Fragment>
        );
      case actionType.Forbidden:
        return (
          <React.Fragment>
            <div className="icon text-danger"></div>
            <div className="header text-danger">
              <div>{props.header}</div>
            </div>
            <div className="sub-header text-danger">
              <p>{props.subHeader}</p>
            </div>
            <div className="modal-dialog-footer">
              {props.handleCancel ? (
                <button
                  type="submit"
                  className="button me-2"
                  onClick={() => handleCancel()}
                >
                  Close
                </button>
              ) : null}
            </div>
          </React.Fragment>
        );
      case actionType.Options:
        return (
          <React.Fragment>
            <div className="icon">
              <h4>{props.header}</h4>
            </div>
            <div className="sub-header">
              <ul>
                {props.options.map((item) => {
                  return (
                    <div className="form-group" key={item.value}>
                      <label className="radio-field">
                        <div className="mb-2">
                          <input
                            className="radio"
                            type="radio"
                            id={item.value}
                            value={item.value}
                            name={props.header}
                            checked={item.isSelected ? true : false}
                            onChange={(e) =>
                              handleOptionsSelection(item, e.target.checked)
                            }
                          />
                          <span className="primary font-weight-medium">
                            {item.value}
                          </span>
                        </div>
                      </label>
                      {item.label ? (
                        <label className="form-label">{item.label}</label>
                      ) : null}
                    </div>
                  );
                })}
              </ul>
            </div>
            <div className="modal-dialog-footer">
              {props.handleOk ? (
                <button
                  type="button"
                  className="button me-2"
                  onClick={() => handleOk()}
                >
                  Ok
                </button>
              ) : null}

              {props.handleCancel ? (
                <button
                  type="submit"
                  className="button danger"
                  onClick={() => handleCancel()}
                >
                  Clear
                </button>
              ) : null}
            </div>
          </React.Fragment>
        );
      default:
        return (
          <div className="header">
            <div>Please confirm</div>
          </div>
        );
    }
  }

  function getClass(type) {
    switch (type) {
      case actionType.Help:
        return "large";
      case actionType.Options:
        return "medium";
      default:
        return "";
    }
  }
  return (
    <React.Fragment>
      <div className="animate fadeIn slow modal-dialog-container">
        <div
          className={`scrollbar modal-dialog-content ${getClass(
            props.actionType
          )} rounded-extra-lg`}
        >
          {getTemplate(props.actionType)}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Modal;
